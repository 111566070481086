import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import SEO from '~/components/seo';
import ProductForm from '../../components/ProductForm/ProductForm';
import {
  Img,
  // Container,
  TwoColumnGrid,
  GridLeft,
  GridRight,
} from './../../utils/styles';
import { ProductTitle, ProductDescription } from './styles';
import { Theme } from '@sashka/theme';

export const Container = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    width: '100%',
    background: theme.palette.background.site,
    marginBottom: '1.45rem',
    marginTop: '6rem',
  };
});

export const ProductDetails = styled('section')<{ theme: Theme }>(({ theme }) => ({
  padding: `0 ${theme.gutters.base * 2}px`,
}));

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct;
  return (
    <>
      <SEO title={product.title} description={product.description} />
      <Container>
        <TwoColumnGrid>
          <GridLeft>
            {product.images.map(image => (
              <Img {...{ fluid: image.localFile.childImageSharp.fluid, key: image.id, alt: product.title }} />
            ))}
          </GridLeft>
          <GridRight>
            <ProductDetails>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductDescription dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
              <ProductForm {...{ product }} />
            </ProductDetails>
          </GridRight>
        </TwoColumnGrid>
      </Container>
    </>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default ProductPage;
